import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class CustomFilterLinkService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}admin/custom-filter-link`;
    }

    all(data){
        let url = `${this.#api}`;

        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }
}
