import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AccountholderService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/accountholder';
    }

    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url);
    }

    getAllByUserType(data) {
        let url = `${this.#api}/get-all?name=${data}`;
        return apiService.query(url);
    }

    paginate(data={},index = null) {

        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getCompleteSearchUrl(data={},index = null) {
        let url = this.#api;
        return url;
    }

    parameterizeArray(key, arr){
        arr = arr.map(encodeURIComponent)
        return '?'+key+'[]=' + arr.join('&'+key+'[]=')
    }

    create(data) {

        let url = `${this.#api}`;
        return apiService.post(url, data);

    }

    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    resetPassword(id,data){
        let url = `${this.#api}/${id}/reset-password`;
        return apiService.post(url,data);
    }
    deactivateAccount(id) {
        let url = `${this.#api}/${id}/deactivate`;
        return apiService.get(url)
    }
    checkDuplicateEmail(data={})
    {
        let url = `${this.#api}/check-duplicate-email`;

        return apiService.post(url,data);
    }
    checkDuplicateEmailForUpdate(id,data={})
    {
        let url = `${this.#api}/${id}/check-duplicate-email`;

        return apiService.post(url,data);
    }
    // acholder export 
    exportToCsv(data = {}){
        let url = `${this.#api}/export/csv`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }
    
    exportToExcel(data = {}){
        let url = `${this.#api}/export/excel`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }

    getExportFilters(){
        let url = `${this.#api}/get-search-parameters`;
        return apiService.get(url);
    }

    getAllLog(id){
        let url = `${this.#api}/${id}/get-logs`;
        return apiService.get(url);
    }
    searchByEmail(email)
    {
        let url = `${this.#api}/search-by/${email}`
        return apiService.get(url)
    }

    filter(data={})
    {
        let url = `${this.#api}/filter`;
        let param = {params: data};
        return apiService.query(url, param);
    }

    loginAsAccountHolder(accountHolderId)
    {
        let url = `${this.#api}/login-as/${accountHolderId}/account-holder`
        return apiService.post(url)
    }

    getAllCart(accountHolderId, data, index)
    {
        let url = `${this.#api}/${accountHolderId}/get-carts`
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url, param)
    }

    viewInvoice(accountHolderId, cartId)
    {
        let url = `${this.#api}/${accountHolderId}/view-invoice/${cartId}`
        window.open(url, "_blank");
    }
    search(data={}){
        let url = `${this.#api}/search/all`;
        let param = {params: data};
        return apiService.query(url, param);
    }
    findAsCandidateUser(data)
    {
        let url = `${this.#api}/find-as-candidate`;
        let param = {
            params: data
        }
        return apiService.query(url, param)
    }

    getSchoolAccount(data={},index = null) {

        let url = `${this.#api}/school-account/all`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getSchoolAccountInformation(accountId) {
        let url = `${this.#api}/school-account/${accountId}`
        return apiService.get(url)
    }

    searchSchoolAccount(data){
        let url = `${this.#api}/search/school-account`;
        let param = {params: data};
        return apiService.query(url, param);
    }
    getByIds(data={})
    {
        let url=`${this.#api}/get/accountholder-by-ids`;
        let param ={
            params: data
        }
        return apiService.query(url,param)
    }
}