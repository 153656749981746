import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class StateService  {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/state';
    }


    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    paginate(data = {}, index = null) {

        let url = this.#api;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index != null && !queryString)
            url = url + '?' + "page=" + (index);
        else
            url = url + '&' + "page=" + (index);
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    get(id) {
        let url = `${this.#api}/${id}/details`
        return apiService.get(url)
    }


    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    all(){
        let url = `${this.#api}/get/all`;
        return apiService.get(url)
    }
}
