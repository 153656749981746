import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CountryService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/country';
    }

    getAllCountry(){
        let url = `${this.#api}`;
        return apiService.get(url)
    }

}