import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;
export default class UserAddressService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user-address';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    show(id) {
        let url =`${this.#api}/${id}`
        return apiService.get(url);
    }
    update(id,data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url,data)
    }
    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

}