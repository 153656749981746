<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Supervisor</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <label><strong>Select enroller type</strong> <span class="text-danger">*</span></label>

              <v-radio-group v-model="supervisor_profile.role" row>
                <v-radio
                    label="Teacher"
                    value="teacher"
                    color="indigo"
                ></v-radio>
                <v-radio
                    label="Parent"
                    value="parent"
                    color="red"
                ></v-radio>
                <v-radio
                    color="indigo darken-3"
                    label="Adult learner"
                    value="adult_learner"
                ></v-radio>
              </v-radio-group>
              <span class="text-danger" v-if="$v.supervisor_profile.role.$error">This information is required</span>


            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                  label="Title" :error="$v.supervisor_profile.title.$error"
                  v-model="supervisor_profile.title"
                  :menu-props="{ top: true, offsetY: true }"
                  :items="titles"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.supervisor_profile.title.$error">This information is required</span>

            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field outlined dense label="First name"
                            :error="$v.supervisor_profile.first_name.$error"  v-model="supervisor_profile.first_name">
                <template v-slot:label>
                  First name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.supervisor_profile.first_name.$error">This information is required</span>

            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field outlined dense  label="Middle name"
                            v-model="supervisor_profile.middle_name"
              ></v-text-field>

            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                  outlined label="Surname"
                  dense :error="$v.supervisor_profile.last_name.$error"
                  v-model="supervisor_profile.last_name">
                <template v-slot:label>
                  Surname <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.supervisor_profile.last_name.$error">This information is required</span>


            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined dense @blur="checkDuplicateEmail" label="Email" :error="$v.supervisor_profile.email.$error"
                            v-model="supervisor_profile.email"
              >
                <template v-slot:label>
                  Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="font-weight-medium text-danger" v-if="messages">{{messages}}</span>
              <span class="text-danger" v-if="$v.supervisor_profile.email.$error">This information is required</span>
              <span class="text-danger" v-if="!$v.supervisor_profile.email.email">This information is not valid</span>


            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined  label="Phone"
                  dense  :error="$v.supervisor_profile.phone.$error"
                  v-model="supervisor_profile.phone"
              >
                <template v-slot:label>
                  Phone <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.supervisor_profile.phone.$error">This information is required</span>

            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined label="Mobile"
                  dense
                  v-model="supervisor_profile.mobile"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined label="Work phone"
                  dense
                  v-model="supervisor_profile.work_phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-checkbox v-model="supervisor_profile.auto_generate">
                <template v-slot:label>
                  <span class="text-left font-weight-medium">
                    Automatically Create a new password
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="!supervisor_profile.auto_generate">
              <v-text-field type="password" dense outlined label="New password" :error="$v.supervisor_profile.new_password.$error"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            v-model="supervisor_profile.new_password">
                <template v-slot:label>
                  New Password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.supervisor_profile.new_password.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-checkbox v-model="supervisor_profile.send_email">
                <template v-slot:label>
                  <span class="text-left font-weight-medium">
                    Send Password in email upon completion
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" v-if="supervisor_profile.send_email">
              <v-combobox
                  v-model="supervisor_profile.group_email"    prepend-inner-icon="mdi-send"
                  hide-selected outlined dense deletable-chips
                  multiple  small-chips
              >
                <template v-slot:label>
                  Emails <span class="text-danger">*</span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Press <kbd>enter</kbd> to create a new Email
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <span class="font-weight-medium subtitle-1">Status</span>
              <v-switch
                  v-model="supervisor_profile.is_active"
                  :label="supervisor_profile.is_active ? 'Active':'Inactive'"
                  hide-details
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="btn btn-primary"
            dark
            medium
            @click="createSupervisor()"
            :loading="loading"  :disabled="buttonDisabled"
        >
          Save
        </v-btn>
        <v-btn
            color="btn btn-standard"
            dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import UserAddressService from "@/services/user/UserAddressService";
import StateService from "@/services/setting/state/StateService";
import CountryService from "@/services/country/CountryService";
import {email, required, requiredIf} from "vuelidate/lib/validators";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";

const accountholder=new AccountholderService();
const supervisor=new SupervisorService();
const country=new CountryService();
const state =new StateService;
const supervisor_profile=new UserAddressService;
export default {
  validations:{
    supervisor_profile:{
      title:{},
      first_name:{required},
      last_name: {required},
      role: {required},
      phone: {required},
      email: {required,email},
      group_email: {
        required: requiredIf(function (nestedModel){
          return this.supervisor_profile.send_email;
        })
      },
      new_password: {
        required: requiredIf(function () {
          return this.supervisor_profile.auto_generate == false;
        })
      },
    }
  },
  data(){
    return{
      title:'',
      dialog: false,
      loading: false,
      supervisor_profile:{
        title:'',
        first_name:'',
        middle_name:'',
        last_name:'',
        role:'',
        phone:'',
        mobile:'',
        work_phone:'',
        is_active:true,
        auto_generate:true,
        new_password:'',
        send_email:0,
        group_email:[]
      },
      errors:[],
      countries:[],
      states:[],
      titles:[
        {name: 'None', value: ''},
        {name: 'Ms', value: 'Ms'},
        {name: 'Miss', value: 'Miss'},
        {name: 'Mrs', value: 'Mrs'},
        {name: 'Mr', value: 'Mr'},
        {name: 'Mx', value: 'Mx'},
        {name: 'Dr', value: 'Dr'},
        {name: 'Prof', value: 'Prof'},
      ],
      duplciateEmailStatus:'',
      messages:'',
      buttonDisabled:false,
      showPassword:false,
    }
  },
  mounted() {

  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {
      this.dialog = true;
    },
    resetForm(){
      this.supervisor_profile={
        title:'',
        first_name:'',
        middle_name:'',
        last_name:'',
        role:'',
        phone:'',
        mobile:'',
        work_phone:'',
        is_active:true,
        auto_generate:true,
        new_password:'',
        send_email:0,
        group_email:[]
      };
      this.showPassword=false;
      this.buttonDisabled=false;
    },
    createUserAddress() {

      this.resetForm();
      this.openDialog();

      this.title = "Add new ";
    },

    createSupervisor() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this._create();

      }
    },
    _create: function () {
      this.loading = true;

      supervisor
          .create(this.supervisor_profile)
          .then(response => {
            this.$snotify.success("Supervisor added");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.supervisor_profile.auto_generate = true;
            this.$emit('refresh',response.data.supervisorId);
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    checkDuplicateEmail(){
      accountholder
          .checkDuplicateEmail(this.supervisor_profile)
          .then(response => {
            this.messages=response.data.message;
            this.duplciateEmailStatus=response.data.status;
            if(this.duplciateEmailStatus=="SUCCESS"){
              this.buttonDisabled=false;
            }else{
              this.buttonDisabled=true;
            }
          })
          .catch(error => {
            this.errors = error.errors;
          })

    },


  },
  computed:{

  }
}
</script>